import React from 'react';
import Layout from '../components/layout';
import Hero from '../components/hero';
import Helmet from 'react-helmet'
import SEO from '../components/seo';
const config ={
  siteTitle: 'Edy Tama Kusumajaya'
}
const IndexPage = () => (
  <Layout>
    <SEO title={'🏠 Home'} />
    {/* <Helmet title={`${config.siteTitle} - Personal Site`} /> */}
    <Hero />
  </Layout>
);

export default IndexPage;
